import resolveConfig from "tailwindcss/resolveConfig";
import config from "../../tailwind.config.cjs";

const tailwindConfig = resolveConfig(config);

const TWMediaQ = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

export { tailwindConfig, TWMediaQ };
