/* eslint-disable @next/next/no-img-element */
import { useState, type ReactNode } from "react";
import { Dialog } from "@headlessui/react";
import {
  IoCloseSharp,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoPinterest,
  IoLogoTiktok,
  IoMenuOutline,
} from "react-icons/io5";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import constants from "app/constants/client";
import ImgWhiteLogo from "app/assets/icons/roomable-logo-white.png";
import ImgIcon from "app/assets/icons/roomable-icon.png";
import Button from "../base/Button";
import { cn } from "app/lib/utils";
import Analytics from "app/utils/analytics-client";

const navigation = [
  { name: "How It Works", href: "#how-it-works" },
  { name: "Features", href: "#features" },
  { name: "Pricing", href: "#pricing" },
];

const TYPEFORM_URL = "https://roomable.typeform.com/to/tFvUamIm";

type ProLandingLayoutProps = {
  children: ReactNode;
};

const ProLandingLayout = ({ children }: ProLandingLayoutProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const router = useRouter();

  return (
    <>
      <div className={`isolate mt-36 bg-black md:mt-28`}>
        <div className="isolate bg-brand-dark">
          <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl md:top-[-20rem]">
            <svg
              className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] md:left-[calc(50%-30rem)] md:h-[42.375rem]"
              viewBox="0 0 1155 678"
            >
              <path
                fill="url(#looking-for-answers)"
                fillOpacity=".3"
                d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
              />
              <defs>
                <linearGradient
                  id="looking-for-answers"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor={"#1d1b26"} />
                  <stop offset={1} stopColor="#f73859" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div className={cn("fixed top-0 z-50 w-full backdrop-blur-[50px]")}>
            <nav
              className="flex items-center justify-between border-b border-solid border-white/10 px-4 py-4 md:px-10"
              aria-label="Global"
            >
              <div className="flex">
                <Link href="/" className="-m-1.5 flex px-1.5">
                  <span className="sr-only">Roomable.ai</span>
                  <div className="py-1.5">
                    <Image
                      src={ImgWhiteLogo}
                      alt=""
                      width={139}
                      height={43}
                      priority
                      placeholder="blur"
                    />
                  </div>
                </Link>
                <div className="ml-[24px] hidden items-center gap-x-6 lg:flex">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      href={item.href}
                      className={`flex flex-row place-items-start items-center text-base font-semibold leading-6 text-white hover:text-brand-red`}
                      onClick={() => {
                        Analytics.proLandingNavBarLink({ navbar_component: item.name });
                      }}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex lg:hidden">
                <Link href={TYPEFORM_URL} target="_blank">
                  <Button
                    onClick={() => {
                      void router.push(`/design`);
                      setMobileMenuOpen(false);
                    }}
                    variant="red"
                    className="mr-[14px] w-[auto] whitespace-nowrap px-3 text-sm font-normal text-white"
                  >
                    Join Roomable
                  </Button>
                </Link>

                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-zinc-50"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <IoMenuOutline className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div className="hidden  py-1 text-base font-semibold leading-6 text-brand-red lg:flex lg:flex-1 lg:justify-end">
                <Link href={TYPEFORM_URL} target="_blank">
                  <Button
                    variant="red"
                    className="w-full whitespace-nowrap px-4 text-base font-normal text-white"
                  >
                    Join Roomable
                  </Button>
                </Link>
              </div>
            </nav>

            <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
              <Dialog.Panel className="fixed inset-0 z-10 overflow-y-auto bg-brand-dark px-6 py-6 lg:hidden">
                <div className="flex items-center justify-between">
                  <Link href="/" className="-m-1.5 p-1.5">
                    <span className="sr-only">Roomable</span>
                    <Image src={ImgIcon} alt="" width={44} height={44} />
                  </Link>
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-zinc-100"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <IoCloseSharp className="h-8 w-8" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-brand-red/10">
                    <div className="space-y-2 py-6 font-normal">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={cn(
                            "-mx-3 flex flex-row place-items-start items-center rounded-lg px-3 py-2 text-lg leading-7  text-white hover:text-brand-red hover:text-brand-red/80"
                          )}
                          onClick={() => {
                            Analytics.proLandingNavBarLink({ navbar_component: item.name });
                          }}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>

                    <div className="-mx-3 block py-6 text-base font-semibold leading-6 text-brand-red hover:text-brand-red/80">
                      <div className="mx-3">
                        <Link href={TYPEFORM_URL} target="_blank">
                          <Button
                            variant="red"
                            className="w-full whitespace-nowrap px-4 text-base font-normal text-white"
                          >
                            Join Roomable
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>

            <div className="flex flex-row justify-center gap-6 overflow-x-auto overscroll-none scroll-smooth whitespace-nowrap px-4 py-1 md:hidden">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  className={cn(
                    `-mx-3 flex flex-row place-items-start items-center rounded-lg px-3 py-2 text-sm font-semibold leading-5 text-white  hover:text-brand-red hover:text-brand-red/80`
                  )}
                  onClick={() => {
                    Analytics.homepageBarLink({ homepage_bar_icon: item.name });
                  }}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>

          <main className="min-h-screen">{children}</main>

          <div className="container mx-auto mt-8 flex flex-col items-start space-y-10 px-4 text-left md:flex-row md:space-y-0">
            <div className="flex-1">
              <Link href="/" className="mb-8 flex ">
                <div className="py-1.5">
                  <Image
                    src={ImgWhiteLogo}
                    alt=""
                    width={139}
                    height={43}
                    priority
                    placeholder="blur"
                  />
                </div>
              </Link>
              <div className="flex flex-row">
                <p className="text-base font-normal leading-6">How can we help? </p>
                <div>
                  <Link
                    href={"mailto:support@roomable.ai"}
                    className="ml-0.5 text-base font-normal leading-6 text-brand-red "
                  >
                    Contact us
                  </Link>
                </div>
              </div>
              <div className="mt-4 flex space-x-4 text-3xl">
                <a href="https://www.facebook.com/roomableai">
                  <IoLogoFacebook />
                </a>
                <a href="https://www.instagram.com/roomableai/">
                  <IoLogoInstagram />
                </a>
                <a href="https://www.linkedin.com/company/roomableai">
                  <IoLogoLinkedin />
                </a>
                <a href="https://www.pinterest.com/roomableai/">
                  <IoLogoPinterest />
                </a>
                <a href="https://www.tiktok.com/@roomableai">
                  <IoLogoTiktok />
                </a>
                <a href="https://twitter.com/roomableai">
                  <img
                    src={`${constants.CloudFront}/assets/X_logo.png`}
                    alt=""
                    height={29}
                    className="h-[29px]"
                  />
                </a>
              </div>

              <div>
                <a href="https://www.iterative.vc/companies" className="cursor-pointer">
                  <p className="mt-8 text-base font-normal leading-6">Proudly backed by </p>
                  <img
                    src={`${constants.CloudFront}/assets/iterative_logo.png`}
                    alt=""
                    height={77}
                    className="h-[77px]"
                  />
                </a>
                <p className="mt-8 hidden text-base font-normal leading-6 md:block">
                  © 2023 Roomable. All rights reserved.
                </p>
              </div>
            </div>
            <div className="flex flex-1 flex-col space-y-10 md:flex-row md:space-y-0">
              <div className="w-full flex-1">
                <div className="mb-6 text-base font-bold">For Professionals</div>
                <div className="space-y-4 font-normal">
                  <div>
                    <Link href="#how-it-works">How It Works</Link>
                  </div>
                  <div>
                    <Link href="#features">Features</Link>
                  </div>
                  <div>
                    <Link href="#pricing">Pricing</Link>
                  </div>
                  <div>
                    <Link href={TYPEFORM_URL} target="_blank">
                      Join Roomable
                    </Link>
                  </div>
                </div>
              </div>
              <div className="w-full flex-1">
                <div className="mb-6 text-base font-bold">For Homeowners</div>
                <div className="space-y-4 font-normal">
                  <div>
                    <Link href="/homeowners#how-it-works">How It Works</Link>
                  </div>
                  <div>
                    <Link href="/homeowners#why-roomable">Why Roomable</Link>
                  </div>
                  <div>
                    <Link href="/discover">Gallery</Link>
                  </div>
                  <div>
                    <Link href="/dashboard">Sign in to Roomable</Link>
                  </div>
                </div>
              </div>
              <div className="w-full flex-1">
                <div className="mb-6 text-base font-bold">Company</div>
                <div className="space-y-4 font-normal">
                  <div>
                    {" "}
                    <Link href="https://www.linkedin.com/company/roomableai">About Us</Link>
                  </div>
                  <div>
                    {" "}
                    <Link href={"mailto:support@roomable.ai"}>Contact</Link>
                  </div>
                </div>
              </div>

              <div className="w-full flex-1">
                <div className="mb-6 text-base font-bold">Legal</div>
                <div className="space-y-4 font-normal">
                  <div>
                    <Link href="/privacy">Privacy Policy</Link>
                  </div>
                  <div>
                    <Link href="/terms">Terms & Conditions</Link>
                  </div>
                </div>
                <p className="mt-8 block text-base font-normal leading-6 md:hidden">
                  © 2023 Roomable. All rights reserved.
                </p>
              </div>
            </div>
          </div>
          <div className="container mx-auto my-12 border-b-2 border-b-zinc-50/10 md:my-28"></div>
        </div>
      </div>
    </>
  );
};

export default ProLandingLayout;
