/* eslint-disable @typescript-eslint/no-var-requires */
const { fontFamily } = require("tailwindcss/defaultTheme");

/** @type {import('tailwindcss').Config} */
const config = {
  darkMode: ["class"],
  content: ["./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    extend: {
      screens: {
        xsm: "450px",
      },
      fontFamily: {
        sans: ["var(--font-ibm)", ...fontFamily.sans],
        sans_serif: ["var(--font-ibm-serif)", ...fontFamily.serif],
      },
      colors: {
        "brand-dark": "#1d1b26",
        "brand-dark-2": "#2D2A34",
        "brand-red": "#f73859",
        "brand-pink": "#E44A5D",
        "brand-green": "#59C9A5",
        "brand-blue": "#2D82B7",
        "brand-yellow": "#fffd98",

        border: "hsl(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        background: "hsl(var(--background))",
        foreground: "hsl(var(--foreground))",
        primary: {
          DEFAULT: "hsl(var(--primary))",
          foreground: "hsl(var(--primary-foreground))",
        },
        secondary: {
          DEFAULT: "hsl(var(--secondary))",
          foreground: "hsl(var(--secondary-foreground))",
        },
        destructive: {
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        card: {
          DEFAULT: "hsl(var(--card))",
          foreground: "hsl(var(--card-foreground))",
        },
      },
      borderRadius: {
        lg: "var(--radius)",
        md: "calc(var(--radius) - 2px)",
        sm: "calc(var(--radius) - 4px)",
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
        slide_up: "slide_up 0.2s",
        "slide-down": "slide-down 0.2s",
        "slide-down-less": "slide-down-less 0.15s",
        "slide-up-less": "slide-up-less 0.15s",
        "text-gradient": "text-gradient 5s ease infinite",
        "text-slide": "text-slide 7s linear infinite",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
        slide_up: {
          from: { transform: "translateY(100%)" },
          to: { transform: "translateY(0)" },
        },
        "slide-down": {
          from: { transform: "translateY(0)" },
          to: { transform: "translateY(100%)" },
        },

        "slide-down-less": {
          from: { transform: "translateY(-10%)" },
          to: { transform: "translateY(0%)" },
        },
        "slide-up-less": {
          from: { transform: "translateY(10%)" },
          to: { transform: "translateY(0%)" },
        },
        "text-gradient": {
          "0%, 100%": {
            "background-size": "200% 200%",
            "background-position": "left center",
          },
          "50%": {
            "background-size": "200% 200%",
            "background-position": "right center",
          },
        },
        "text-slide": {
          "0%": { transform: "translateY(100%)", opacity: "0.02" },
          "15%": { transform: "translateY(0)", opacity: "1" },
          "30%": { transform: "translateY(0)", opacity: "1" },
          "45%": { transform: "translateY(-100%)", opacity: "0.02" },
          "100%": { transform: "translateY(-100%)", opacity: "0.01" },
        },
      },
    },
  },
  // @ts-ignore
  plugins: [require("@tailwindcss/forms"), require("tailwindcss-animate")],
};

module.exports = config;
