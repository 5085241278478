/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import Link from "next/link";
import React, { type ReactElement, useState } from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Switch } from "@headlessui/react";

import ProLandingLayout from "app/components/layout/ProLandingLayout";
import constants from "app/constants/client";
import useWindowSize from "app/hooks/useWindowSize";
import { TWMediaQ } from "app/utils/tailwindConfig";
import ImgLetsStart from "app/assets/pro-landing-page-plan-illustration.png";
import Button from "app/components/base/Button";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { cn } from "app/lib/utils";

import ReferralImage from "../assets/pro-onboarding/referral.png";
import ShowCaseImage from "../assets/pro-onboarding/showcase.png";
import PremiumLeadImage from "../assets/pro-onboarding/premiumLead.png";
import TrackReferralImage from "../assets/pro-onboarding/trackReferral.png";
import NadiaClarissaImage from "../assets/pro-onboarding/NadiaClarissa.png";
import VeraLiuImage from "../assets/pro-onboarding/VeraLiu.png";

const TYPEFORM_URL = "https://roomable.typeform.com/to/tFvUamIm";

const ProLandingPage = () => {
  const windowSize = useWindowSize();
  const [isAnnual, setIsAnnual] = useState<boolean>(false);
  const [selectedFeatureIdx, setSelectedFeatureIdx] = useState<number>(0);
  const [lastSelectedFeatureIdx, setLastSelectedFeatureIdx] = useState<number>(0);
  const [selectedTestimonialIdx, setSelectedTestimonialIdx] = useState<number>(0);

  const referrals = [
    {
      image: "referral-step-1.png",
      title: "Join Roomable",
      description: "Sign up as a Pro and get access to exclusive referral tools for your business.",
    },
    {
      image: "referral-step-2.png",
      title: "Set your referral terms",
      description:
        "Choose how you'll reward your existing clients for sharing exclusive leads with your business.",
    },
    {
      image: "referral-step-3.png",
      title: "Promote your program",
      description: "Share with your existing clients and supercharge your word of mouth marketing",
    },
    {
      image: "referral-step-4.png",
      title: "Get premium leads",
      description:
        "Receive high-value leads shared exclusively with your business from your existing client network.",
    },
  ];
  const ImgAssetURL = `${constants.CloudFront}/pro-homepage`;

  const features = [
    {
      title: "Manage your referrals in one place",
      description:
        "Set your commission rates and choose how you'll reward your clients for providing you with exclusive leads. Get your program up and running in minutes.",
      image: ReferralImage,
    },
    {
      title: "Showcase your portfolio",
      description:
        "Referred leads from existing clients will land on your portfolio page where you can easily show off your best work.",
      image: ShowCaseImage,
    },
    {
      title: "Get premium leads qualified with AI",
      description:
        "Stop wasting your time on low-quality leads that don't convert into clients. We qualify all your leads with AI to ensure you receive only the best of the best.",
      image: PremiumLeadImage,
    },
    {
      title: "Track your referral results",
      description:
        "Our referral dashboard makes it simple to see what's working and what isn't, empowering you to easily maximize your ROI.",

      image: TrackReferralImage,
    },
  ];

  const TESTIMONIALS = [
    {
      name: "Nadia Clarissa",
      name_sub: "Interior Designer at Studio Smol",
      image: NadiaClarissaImage,
      comment: (
        <span className="text-white/80">
          The ease and cost-effectiveness of Roomable has transformed my lead generation process. By
          utilizing my existing client base for referrals,{" "}
          <span className="font-bold text-white/100">
            I receive vetted leads without the hassle of manual qualification or competition
          </span>
        </span>
      ),
    },
    {
      name: "Vera Liu",
      image: VeraLiuImage,
      name_sub: "Interior Designer at Mason Miller",
      comment: (
        <span className="text-white/80">
          Roomable has revolutionized how we engage with clients and secure high-quality referrals,
          saving us time and money. The platform is user-friendly and ensures{" "}
          <span className="font-bold text-white/100">
            we don&apos;t compete with other businesses for leads,
          </span>{" "}
          giving us a clear advantage
        </span>
      ),
    },
  ];

  return (
    // <div className="">
    <div className="container mx-auto flex max-w-7xl flex-col items-center gap-6 px-4 pb-20">
      <section id="hero">
        <div className="relative w-full">
          <div className="container relative mx-auto lg:px-6">
            <div className="mx-auto md:max-w-6xl">
              <div className="flex flex-col items-center justify-center text-center">
                <span className="text-xs font-semibold leading-[18px] text-brand-pink ">
                  FOR HOME PROFESSIONALS
                </span>
                <h1 className="mt-7 max-w-full text-[40px] font-semibold leading-[48px] tracking-tight text-white md:max-w-5xl md:text-7xl md:leading-[86px]">
                  Supercharge Your Business with Exclusive Client Referrals
                </h1>
                <p className="mx-auto mt-4 max-w-full px-4 text-base font-normal leading-6 md:max-w-2xl md:text-xl md:leading-[30px]">
                  Roomable provides interior designers with premium leads using AI-powered referrals
                  from your existing base of satisfied clients.
                </p>

                <div className="flex flex-col items-center justify-center">
                  <Link
                    href={TYPEFORM_URL}
                    target="_blank"
                    className="mb-7 mt-6 flex w-[auto] items-center justify-center rounded-md bg-brand-pink px-6 py-[11px] text-lg font-semibold text-white shadow-lg hover:bg-brand-pink/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 md:mb-5 md:mt-12 "
                  >
                    <span>Join Roomable</span>
                  </Link>
                  <Image
                    src={`${ImgAssetURL}/home-professional-lg.png`}
                    width={1238}
                    height={505}
                    alt=""
                    className="hidden justify-end md:block"
                  />
                  <Image
                    src={`${ImgAssetURL}/home-professional-sm.png`}
                    width={361}
                    height={333}
                    alt=""
                    className="block md:hidden"
                  />
                </div>

                <div className="mt-7 flex flex-col items-center justify-center md:mt-0 md:flex-row md:gap-8">
                  <div className="flex h-[64px] items-center justify-center whitespace-nowrap text-base font-normal leading-normal text-white text-opacity-80">
                    As seen on
                  </div>
                  <div className="mt-[-10px] flex flex-row items-center justify-between md:mt-0 md:gap-8">
                    <img
                      src={`${constants.CloudFront}/assets/commendations/House Beautiful.svg`}
                      alt="House Beautiful"
                      width={146}
                      height={64}
                      className="h-[54px] px-1 md:h-[64px] md:px-0.5"
                    />
                    <img
                      src={`${constants.CloudFront}/assets/commendations/The NYT.svg`}
                      alt="The New York Times"
                      width={178}
                      height={64}
                      className="h-[54px] px-1 md:h-[64px] md:px-0.5"
                    />
                  </div>
                  <img
                    src={`${constants.CloudFront}/assets/commendations/ArchitectureDigest.svg`}
                    alt="Architecture Digest"
                    width={252}
                    height={64}
                    className="mt-[-10px] h-[64px] px-1 md:mt-0 md:px-0.5"
                  />
                  <div className="mt-[-10px] flex flex-row items-center justify-evenly md:mt-0 md:gap-8">
                    <img
                      src={`${constants.CloudFront}/assets/commendations/Elle Decor.svg`}
                      alt="Elle Decor"
                      width={124}
                      height={64}
                      className="h-[54px] px-1 md:h-[64px] md:px-0.5"
                    />
                    <img
                      src={`${constants.CloudFront}/assets/commendations/TechCrunch.svg `}
                      alt="Tech Crunch"
                      width={82}
                      height={64}
                      className="h-[54px] px-1 md:h-[64px] md:px-0.5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container relative mt-20 px-4 lg:px-8" id="how-it-works">
        {/* How it works section start*/}
        <section className="mb-20">
          <div>
            <div className="mb-10 flex flex-col justify-between md:flex-row md:items-center">
              <div className="flex flex-col items-start">
                <span className="text-xs font-semibold leading-[18px] text-brand-pink">
                  HOW IT WORKS
                </span>

                <div className="mt-3">
                  <span className="text-[32px] font-semibold leading-10  tracking-tight">
                    Unlock the power of referrals in{" "}
                  </span>
                  <span className="font-sans_serif text-[32px] font-semibold italic leading-10 tracking-tight">
                    4 easy steps
                  </span>
                </div>
              </div>
            </div>
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                [TWMediaQ.sm]: 1,
                [TWMediaQ.md]: 4,
                [TWMediaQ.lg]: 4,
                [TWMediaQ.xl]: 4,
              }}
            >
              <Masonry gutter={constants.isMobile(windowSize) ? "32px" : "64px"}>
                {referrals.map((item, i) => {
                  return (
                    <div className="mt-1 flex flex-col" key={i}>
                      <Image src={`${ImgAssetURL}/${item.image}`} width={62} height={62} alt="" />
                      <div className="mt-3 flex flex-row items-center">
                        <span className="text-left text-2xl font-semibold leading-9">
                          {item.title}
                        </span>
                      </div>
                      <p className="mt-1 flex items-start text-base font-normal leading-6 text-white/80 text-opacity-80">
                        {item.description}
                      </p>
                    </div>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
            <div className="mt-8 flex flex-col items-center justify-center md:mt-12">
              <Link
                href={TYPEFORM_URL}
                target="_blank"
                className="flex w-full items-center justify-center rounded-md bg-brand-pink px-6 py-[11px] text-lg font-semibold text-white shadow-lg hover:bg-brand-pink/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 md:w-[272px] "
              >
                Join Now
              </Link>
            </div>
          </div>
        </section>
        {/* How it works section end*/}
      </div>

      <div className="container relative mt-20 hidden px-4 lg:flex lg:px-8">
        {/* FEATURES section start*/}
        <section id="features" className="mb-20">
          <div>
            <div className="mb-10 flex flex-col justify-between md:flex-row md:items-center">
              <div className="flex w-full flex-col items-start">
                <span className="text-xs font-semibold leading-[18px] text-brand-pink">
                  FEATURES
                </span>
                <div className="mt-3">
                  <span className="text-[32px] font-semibold leading-10 tracking-tight">
                    The world&apos;s top referral management solution for home professionals
                  </span>
                </div>
                <div className="mt-10 flex w-full flex-row items-start justify-between">
                  <div className="flex flex-1 flex-col gap-2">
                    {features.map((item, featureIdx) => (
                      <div
                        key={featureIdx}
                        className={cn(
                          "flex flex-col gap-2 rounded-xl p-5 transition-colors ",
                          featureIdx === selectedFeatureIdx
                            ? "cursor-default bg-white/10 duration-500"
                            : "cursor-pointer text-white/70 hover:text-white/100",
                          featureIdx === selectedFeatureIdx
                            ? featureIdx > lastSelectedFeatureIdx
                              ? " animate-slide-down-less "
                              : " animate-slide-up-less"
                            : ""
                        )}
                        onClick={() => {
                          const currentIdx = selectedFeatureIdx;
                          setLastSelectedFeatureIdx(currentIdx);
                          setSelectedFeatureIdx(featureIdx);
                        }}
                      >
                        <span className={cn("text-xl font-semibold")}>{item.title}</span>
                        {featureIdx === selectedFeatureIdx && (
                          <span className="text-base font-normal text-white/70">
                            {item.description}
                          </span>
                        )}
                      </div>
                    ))}
                    <Link href={TYPEFORM_URL}>
                      <Button
                        variant={"red"}
                        className="mt-4 w-max px-6 py-3 text-base font-medium  "
                      >
                        Join Roomable
                      </Button>
                    </Link>
                  </div>
                  <div className="flex flex-1  flex-col  items-end ">
                    {features.map((item, i) => (
                      <Image
                        key={i}
                        src={item.image}
                        alt={""}
                        className={` w-4/5 ${selectedFeatureIdx === i ? "" : "hidden"}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FEATURES section end*/}
      </div>

      <div className="container relative mt-20 flex px-4 lg:hidden lg:px-8">
        {/* FEATURES section start*/}
        <section id="features">
          <div>
            <div className="mb-10 flex flex-col justify-between md:flex-row md:items-center">
              <div className="flex w-full flex-col items-start">
                <span className="text-xs font-semibold leading-[18px] text-brand-pink">
                  FEATURES
                </span>
                <div className="mt-3">
                  <span className="text-[32px] font-semibold leading-10 tracking-tight">
                    The world&apos;s top referral management solution for home professionals
                  </span>
                </div>
                <div className="mt-10 flex w-full flex-row items-start justify-between">
                  <div className="flex flex-1 flex-col gap-6">
                    {features.map((item, featureIdx) => (
                      <div key={featureIdx} className="my-6 flex flex-col gap-6">
                        <span className="flex flex-col gap-2">
                          <p className="text-xl font-semibold">{item.title}</p>
                          <p className="text-sm font-normal text-white/70">{item.description}</p>
                        </span>

                        <Image src={item.image} alt={""} className="w-full" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FEATURES section end*/}
      </div>

      <div className="container relative mt-20 w-full px-4 lg:px-8">
        {/* TESTIMONIALS section start*/}
        <section id="features" className="mb-20">
          <div>
            <div className="mb-10 flex w-full flex-col items-center  justify-between">
              <div className="flex w-full flex-col items-start">
                <span className="text-xs font-semibold leading-[18px] text-brand-pink">
                  TESTIMONIALS
                </span>
                <div className="mt-3">
                  <span className="text-[32px] font-semibold leading-10 tracking-tight">
                    Why Roomable is the best choice for your business
                  </span>
                </div>
                <div className="mt-3">
                  <span className="text-lg font-normal tracking-tight text-white/80">
                    Roomable is loved by home professionals all over the world.
                  </span>
                </div>
                <div className="mt-10 w-full">
                  <div className="flex min-h-[250px] w-full flex-col items-center justify-center rounded-xl bg-white/10 px-6 py-10 text-center md:px-20">
                    <div className="text-center text-xl font-normal leading-7 md:text-[32px] md:leading-10">
                      “ {TESTIMONIALS[selectedTestimonialIdx]?.comment} ”
                    </div>
                    <div className="mt-6 flex flex-row items-center gap-4">
                      {TESTIMONIALS[selectedTestimonialIdx]?.image && (
                        <Image
                          src={TESTIMONIALS[selectedTestimonialIdx]?.image || ""}
                          alt=""
                          className="h-14 w-14 rounded-full  "
                        />
                      )}
                      <span className="flex flex-col text-left">
                        <p className="text-base font-medium">
                          {TESTIMONIALS[selectedTestimonialIdx]?.name || ""}
                        </p>
                        <p className="font-normal text-white/70 md:text-base">
                          {TESTIMONIALS[selectedTestimonialIdx]?.name_sub || ""}
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className="mt-10 flex flex-row justify-center gap-6">
                    <Button
                      variant={"ghost"}
                      onClick={() => {
                        if (selectedTestimonialIdx === 0) {
                          setSelectedTestimonialIdx(TESTIMONIALS.length - 1);
                        } else {
                          setSelectedTestimonialIdx(selectedTestimonialIdx - 1);
                        }
                      }}
                    >
                      <HiArrowLongLeft size={40} />
                    </Button>
                    <Button
                      variant={"ghost"}
                      onClick={() => {
                        if (selectedTestimonialIdx === TESTIMONIALS.length - 1) {
                          setSelectedTestimonialIdx(0);
                        } else {
                          setSelectedTestimonialIdx(selectedTestimonialIdx + 1);
                        }
                      }}
                    >
                      <HiArrowLongRight size={40} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* TESTIMONIALS section end*/}
      </div>

      {/* Our leads in numbers Section Start */}
      <div className="container mx-auto mt-10 flex flex-col px-4 lg:px-8 xl:flex-row">
        <div className="flex flex-col items-start gap-4 xl:w-2/5 xl:max-w-[405px]">
          <div className="text-2xl font-semibold leading-9">Our leads in numbers</div>
          <div className="text-base font-normal leading-normal">
            Stop wasting time and money on low-quality leads and focus on what you do best:{" "}
            <span className="font-sans_serif italic">designing beautiful living spaces.</span>
          </div>
          <Link
            href={TYPEFORM_URL}
            target="_blank"
            className="inline-flex w-full items-center justify-center gap-3 rounded-md bg-brand-pink px-6 py-[11px] font-semibold leading-normal xl:w-fit xl:max-w-fit"
          >
            Join Roomable
          </Link>
        </div>

        <div className="mx-16 hidden h-auto w-[1px] bg-white/10 xl:block" />

        <div className="my-8 flex flex-row flex-wrap justify-center md:my-6 xl:w-[60%] xl:flex-nowrap xl:justify-between">
          <div className="flex w-1/2 flex-col items-center justify-center gap-1 px-3 xl:w-1/3 xl:px-6">
            <div className="text-[40px] font-semibold leading-[52px] tracking-tight text-brand-pink xl:text-[64px] xl:leading-[83.20px] xl:tracking-wide">
              30%
            </div>
            <div className="text-center text-base font-normal leading-normal xl:whitespace-nowrap">
              more likely to
              <br className="md:hidden" /> convert
              <br />
              into paying clients
              <br />
              &thinsp;
            </div>
          </div>

          <div className="flex w-1/2 flex-col items-center justify-center gap-1 px-3 xl:w-1/3 xl:px-6">
            <div className="text-[40px] font-semibold leading-[52px] tracking-tight text-brand-pink xl:text-[64px] xl:leading-[83.20px] xl:tracking-wide">
              16%
            </div>
            <div className="text-center text-base font-normal leading-normal xl:whitespace-nowrap">
              more spend on interior
              <br />
              design projects
              <br />
              &thinsp;
            </div>
          </div>

          <div className="w-1/2x flex flex-col items-center justify-center gap-1 px-3 xl:w-1/3 xl:px-6">
            <div className="text-center text-[40px] font-semibold leading-[52px] tracking-tight text-brand-pink xl:text-[64px] xl:leading-[83.20px] xl:tracking-wide">
              35%
            </div>
            <div className="text-center text-base font-normal leading-normal xl:whitespace-nowrap">
              cost savings compared to
              <br />
              traditional marketing
              <br />
              channels
            </div>
          </div>
        </div>
      </div>
      {/* Our leads in numbers Section End */}

      {/* Let's Get Started Section Start */}
      <div className="container mx-auto mt-20 px-4 lg:py-8" id="pricing">
        <div
          className="flex w-full flex-col justify-between rounded-xl p-8 md:flex-row"
          style={{
            boxShadow: "0px 15px 17px 0px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(50px)",
            background: constants.isMobile(windowSize)
              ? "linear-gradient(179deg, #E44A5D -8.29%, rgba(228, 74, 93, 0.50) -8.28%, rgba(184, 56, 71, 0.40) 30.86%, rgba(206, 65, 82, 0.41) 71.79%, rgba(228, 74, 93, 0.50) 105.59%)"
              : "linear-gradient(90deg, #E44A5D -0.15%, rgba(228, 74, 93, 0.50) -0.14%, rgba(184, 56, 71, 0.40) 34%, rgba(206, 65, 82, 0.41) 69.7%, rgba(228, 74, 93, 0.50) 99.19%)",
          }}
        >
          <div className="flex w-full flex-col items-center justify-center md:w-[60%] md:items-start">
            <div className="mb-2 text-center text-[32px] font-semibold leading-[41.60px] md:text-left">
              Let&apos;s get started
            </div>
            <div className="mb-6 text-center text-base font-normal leading-normal text-white text-opacity-80 md:text-left">
              Our affordable plans make it easy for any home professional to supercharge their
              business!
            </div>

            <div className="flex items-center justify-center">
              <div
                onClick={() => setIsAnnual(false)}
                className={`cursor-pointer text-sm md:text-lg ${
                  isAnnual ? "text-white text-opacity-80" : "text-white"
                }`}
              >
                Monthly
              </div>
              &nbsp;&nbsp;
              <Switch
                checked={isAnnual}
                onChange={setIsAnnual}
                className="relative
          inline-flex h-[24px] w-[49px] shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-brand-red transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75"
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${isAnnual ? "translate-x-6" : "translate-x-0"}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              &nbsp;&nbsp;
              <div
                onClick={() => setIsAnnual(true)}
                className={`cursor-pointer text-sm md:text-lg ${
                  isAnnual ? "text-white" : "text-white text-opacity-80"
                }`}
              >
                Annual <b>(Save 30%)</b>
              </div>
            </div>

            <div className="flex flex-row items-center justify-center">
              <div className="mr-1 text-center text-7xl font-semibold leading-[108px] text-white">
                ${isAnnual ? 579 : 69}
              </div>
              <div className="flex flex-col items-start justify-center">
                <div className="text-base font-normal leading-normal text-white/90">
                  /{isAnnual ? "year" : "month"}
                </div>
                <div className="mt-[3px] hidden text-center text-sm font-normal leading-[21px] text-white/90 md:block">
                  {isAnnual ? "30% off" : "Risk-free, cancel anytime"}
                </div>
              </div>
            </div>
            <div className="mt-[-18px] text-center text-sm font-normal leading-[21px] text-white text-opacity-50 md:hidden">
              {isAnnual ? "30% off" : "Risk-free, cancel anytime"}
            </div>

            <div className="my-[13px]">
              <Link
                href={TYPEFORM_URL}
                target="_blank"
                className="inline-flex w-full items-center justify-center gap-3 rounded-md bg-brand-pink px-6 py-[11px] font-semibold leading-normal md:w-fit md:max-w-fit"
              >
                Join Roomable
              </Link>
            </div>

            <div className="my-2 flex flex-col gap-2 md:my-4 md:flex-row md:gap-6">
              <div className="flex flex-col gap-2 md:gap-2">
                <div className="flex flex-row items-center gap-2">
                  <FaRegCircleCheck size={20} />
                  <div className="text-base font-normal leading-normal text-white">
                    Unlimited client referrals
                  </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <FaRegCircleCheck size={20} />
                  <div className="text-base font-normal leading-normal text-white">
                    Free access to new features
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2 md:gap-2">
                <div className="flex flex-row items-center gap-2">
                  <FaRegCircleCheck size={20} />
                  <div className="text-base font-normal leading-normal text-white">
                    Unlimited monthly leads
                  </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <FaRegCircleCheck size={20} />
                  <div className="text-base font-normal leading-normal text-white">
                    Priority customer support
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2 md:gap-2">
                <div className="flex flex-row items-center gap-2">
                  <FaRegCircleCheck size={20} />
                  <div className="text-base font-normal leading-normal text-white">
                    Lifetime pricing
                  </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <FaRegCircleCheck size={20} />
                  <div className="text-base font-normal leading-normal text-white">
                    High quality leads
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Image
            src={ImgLetsStart}
            height={386}
            width={386}
            alt=""
            className="hidden aspect-square rounded-3xl object-contain opacity-95 md:block"
            placeholder="blur"
          />
        </div>
      </div>
      {/* Let's Get Started Section End */}
    </div>
  );
};

ProLandingPage.getLayout = function getLayout(page: ReactElement) {
  return <ProLandingLayout>{page}</ProLandingLayout>;
};

export default ProLandingPage;
